@charset "UTF-8";
@import url(https://fonts.googleapis.com/earlyaccess/notosansjp.css);

body {
  font-family: 'Noto Sans JP';
  font-weight: normal;
  font-size: 100%;
}

a {
  text-decoration: none;
}

p {
  letter-spacing: 0.5px;
  line-height: 2;
}

menu {
  list-style-type: none;
  margin-block-start: 0;
  margin-block-end: 0;
  margin-inline-start: 0;
  margin-inline-end: 0;
  padding-inline-start: 0;
}

#wrap {
  opacity: 0;
  transition: 0.3s cubic-bezier(0.165, 0.84, 0.44, 1);
  overflow: hidden;
  &.is_show {
    opacity: 1;
  }
}